<template>
  <LayoutFilter
    :toggleCollapseEnable="false"
    :onFilter="ok"
    :onReset="resetForm"
    :onExport="onExport"
  >
    <el-form
      :model="filterForm"
      :rules="rules"
      ref="filterFilterForm"
      label-width="85px"
      size="medium"
      class="filter-from"
      @submit.native.prevent
    >
      <div class="filter-item">
        <!--        当一个 form 元素中只有一个输入框时，在该输入框中按下回车应提交该表单。
        如果希望阻止这一默认行为，可以 在 标签上添加 @submit.native.prevent。-->
        <!-- <el-form-item label="老师ID：" prop="id">
          <el-input
            @keyup.enter.native="ok"
            v-model="form.id"
            placeholder="请输入"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="电子书名称：" prop="keyword">
          <el-input
            @keyup.enter.native="ok"
            v-model="form.keyword"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="电子书来源：" prop="source_type">
          <el-select v-model="form.source_type" placeholder="请选择">
            <el-option
              v-for="item in sourceTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择分类：" prop="category_id">
          <el-select v-model="form.category_id" placeholder="请选择">
            <el-option
              v-for="item in optionList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上架状态：" prop="is_post">
          <div class="flex-align-center">
            <el-select v-model="form.is_post">
              <el-option
                v-for="ca in publishOptions"
                :key="ca.id"
                :label="ca.name"
                :value="ca.id"
              />
            </el-select>
          </div>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker
          label="创建时间："
          start-prop="post_start_time"
          :start-time.sync="form.post_start_time"
          end-prop="post_end_time"
          :end-time.sync="form.post_end_time"
        />
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import { exportFile } from '@/modules/college/api/ele-book-list.js'
import { categoryList } from '@/modules/college/api/learn-category-list'
export default {
  name: 'ListFilter',
  components: { DatePeriodPicker, LayoutFilter },
  props: {
    filterForm: {
      type: Object,
    },
    //导出要用的数据
    idList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rules: {},
      form: null,
      optionList: [],
      // 来源类型
      sourceTypeList: [
        {
          id: 0,
          name: '自建',
        },
        {
          id: 1,
          name: '课程库',
        },
        {
          id: 2,
          name: '得到',
        },
        {
          id: 3,
          name: '量子',
        },
        {
          id: 4,
          name: '喜马拉雅',
        },
      ],
      // 上架状态
      publishOptions: [
        { name: '全部', id: -1 },
        { name: '已上架', id: 1 },
        { name: '未上架', id: 0 },
      ],
    }
  },
  watch: {
    filterForm: {
      deep: true,
      handler(val) {
        this.form = val
      },
    },
  },
  created() {
    this.form = this.filterForm
    this.getCategoryList()
  },
  methods: {
    //导出
    onExport() {
      return exportFile({
        export_ids: this.idList,
        ...this.form,
      }).catch(() => {})
    },
    //重置表单
    resetForm() {
      this.$refs['filterFilterForm'].resetFields()
      this.ok()
    },
    //筛选
    ok() {
      this.$emit('update', this.form)
    },
    // 获取分类列表
    getCategoryList() {
      categoryList({ tab: 'normal' }).then((res) => {
        console.log(res)
        this.optionList = res.data.list
      })
    },
  },
}
</script>

<style scoped></style>
