<!--
 * @Author: mulingyuer
 * @Date: 2021-09-02 11:17:04
 * @LastEditTime: 2021-09-03 16:29:56
 * @LastEditors: mulingyuer
 * @Description: 修改分类弹窗
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\components\course-manage\index\ChangeCategoryDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog
    title="编辑分类"
    :visible.sync="show"
    width="500px"
    class="dialog-vertical change-category-dialog"
    @close="onClose"
    @open="onOpen"
  >
    <div class="content" v-loading="loading">
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        size="medium"
        class="medium-form"
      >
        <el-form-item label="分类：" prop="category_ids">
          <el-select multiple v-model="form.category_ids" placeholder="请选择分类">
            <el-option
              v-for="op in categoryArr"
              :key="op.id"
              :label="op.name"
              :value="op.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="small" @click="show = false">取消</el-button>
      <el-button
        v-if="!loading"
        size="small"
        type="primary"
        :loading="saveLoading"
        @click="onConfirm('ruleForm')"
        >确认
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { updateCategories } from '@/modules/college/api/ele-book-list.js'
import { categoryList } from '@/modules/college/api/learn-category-list.js'

export default {
  model: {
    prop: 'open',
    event: 'handleOpen',
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    //选中的id
    activeIds: {
      type: Array,
      required: true,
    },
    //完成后的回调
    complete: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false, //加载中
      form: {
        category_ids: [], //分类ID
      },
      rules: {
        category_ids: [
          { required: true, message: '请选择分类', trigger: 'change' },
        ],
      },
      saveLoading: false, //保存中
      categoryArr: [], //分类选项数据
    }
  },
  computed: {
    show: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('handleOpen', val)
      },
    },
  },
  methods: {
    //关闭
    onClose() {
      this.resetForm()
    },
    //打开
    onOpen() {
      this.$nextTick(() => {
        this.resetForm()
        this.getCategoryArr()
      })
    },
    //确认
    onConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          const postData = { ids: this.activeIds, ...this.form }
          updateCategories(postData)
            .then((res) => {
              const { msg } = res
              this.$message.success(msg)

              this.complete()
              this.saveLoading = false
              this.show = false
            })
            .catch(() => {
              this.saveLoading = false
            })
        }
      })
    },
    resetForm() {
      this.$refs['ruleForm'].resetFields()
    },
    //获取分类数据
    getCategoryArr() {
      this.loading = true
      categoryList({ tab: 'normal' })
        .then((res) => {
          const { data } = res
          this.categoryArr = data.list

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
