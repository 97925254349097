<template>
  <div class="box">
    <list-layout
      ref="listLayout"
      :filter-form="filterForm"
      :thead="thead"
      :tabArr="tabArr"
      :on-fetch="getList"
      :on-edit="handleAdd"
      :on-delete="onDelete"
      :on-put-back="onPutBack"
      :on-update="onUpdate"
      @command="handleClickRow"
    >
      <template #top>
        <el-button
          class="top-btn"
          size="small"
          type="primary"
          @click="handleAdd()"
          >添加电子书</el-button
        >
      </template>
      <template #tabletop="{ idList, list }">
        <el-button
          type="success"
          size="small"
          v-if="filterForm.tab === 'deleted'"
          @click="putBackSelected"
          >还原</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="saveUpdatePost(idList, 1)"
          >批量上架</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="saveUpdatePost(idList, 0)"
          >批量下架</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="batchChangeCategory(idList)"
          >批量修改分类</el-button
        >
        <el-button size="small" type="danger" @click="deleteSelected"
          >删除</el-button
        >
      </template>

      <template #filter="{ idList }">
        <list-filter :idList="idList" :filter-form="filterForm" @update="ok" />
      </template>

      <template #item_categories="{ row }">{{
        categoriesText(row.categories)
      }}</template>
    </list-layout>
    <!-- 修改分类弹窗 -->
    <ChangeCategoryDialog
      v-model="openChangeCategoryDialog"
      :activeIds="activeIds"
      :complete="ok"
    />
  </div>
</template>

<script>
import ListLayout from '@/base/layout/ListLayout'
import {
  updatePost,
  getList,
  saveDetail,
  softDelete,
  Delete,
  putBack,
} from '@/modules/college/api/ele-book-list'
import ListFilter from '@/modules/college/components/EleBookListFilter'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall'
import ChangeCategoryDialog from '@/modules/college/components/EleChangeCategoryDialog'
import { union } from 'lodash'

const DIALOG_MODEL = {
  id: '',
  avatar: '',
  nickname: '',
  phone: '',
}

export default {
  name: 'list',
  components: { SingleMediaWall, ListLayout, ListFilter, ChangeCategoryDialog },
  data() {
    return {
      // tab配置
      tabArr: [
        { label: '全部', name: 'normal' },
        { label: '回收站', name: 'deleted' },
      ],
      // 选中的数据
      activeIds: [],
      // 修改分类弹窗
      openChangeCategoryDialog: false,
      filterForm: {
        page: 1,
        page_size: 15,
        is_desc: 1,
        order_by: 'id',
        tab: 'normal',
        post_start_time: -1,
        post_end_time: -1,
        category_id: '',
        source_type: '',
        is_post: -1,
      },
      thead: [
        { type: 'selection', width: 80 },
        { label: '电子书ID', prop: 'id', width: 140 },
        { label: '电子书名称', prop: 'name', minWidth: 100 },
        { label: '电子书封面', prop: 'cover', minWidth: 150, type: 'image' },
        // { label: '老师名称', prop: 'teacher_name', minWidth: 150 },
        { label: '分类', prop: 'categories', minWidth: 200, type: 'slot' },
        { label: '第三方分类', prop: 'third_party_category', minWidth: 150 },
        { label: '课程来源', prop: 'source_text', minWidth: 100 },
        { label: '加入书架人数', prop: 'bookshelf_count', minWidth: 150 },
        { label: '阅读人数', prop: 'views_count', minWidth: 100 },
        { label: '上架时间', prop: 'post_time_text', minWidth: 200 },
        // { label: '老师评价', prop: 'courses_count', minWidth: 200 },
        {
          label: '操作',
          type: 'operation',
          width: 200,
          operations: [
            { command: 'edit', text: '编辑' },
            {
              command: 'updatePostUp',
              text: '上架',
              visible: (row, query) => {
                // console.log(query)
                return !row.is_post && query.tab == 'normal'
              },
            },
            {
              command: 'updatePostDown',
              text: '下架',
              visible: (row, query) => row.is_post && query.tab == 'normal',
            },
            { command: 'delete', text: '删除' },
          ],
        },
      ],
      dialog: {
        show: false,
        data: JSON.parse(JSON.stringify(DIALOG_MODEL)),
        editLoading: false,
        loading: false,
      },
    }
  },
  methods: {
    // 批量修改分类
    batchChangeCategory(idList) {
      this.activeIds = idList
      this.openChangeCategoryDialog = true
    },
    putBackSelected() {
      this.$refs.listLayout.putBackSelected()
    },
    deleteSelected() {
      this.$refs.listLayout.deleteSelected()
    },
    onUpdate(data) {
      // return saveSort(data)
    },
    /**
     * 列表
     * @param params
     * @returns {AxiosPromise}
     */
    getList(params) {
      return getList(params)
    },
    /**
     * 新增、编辑
     * @param obj
     */
    handleAdd(obj = {}) {
      this.$router.push({
        name: 'AddCollegeEleBook',
        params: {
          id: obj.id || 0,
        },
      })
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 }
      this.$refs.listLayout.getList(this.filterForm)
    },
    //  还原
    onPutBack(idList) {
      return putBack({ id: idList })
    },
    /**
     * 弹窗
     */
    confirm() {
      this.dialog.loading = true
      saveDetail(this.dialog.data)
        .then((res) => {
          this.$message.success(res.msg)
          this.close()
          this.$refs.listLayout.getList(this.filterForm)
        })
        .catch((err) => {})
        .finally(() => {
          this.dialog.loading = false
        })
    },
    // 数据删除
    onDelete(idList, forever = false) {
      return forever ? Delete({ id: idList }) : softDelete({ id: idList })
    },
    close() {
      this.dialog.show = false
    },
    categoriesText(categories) {
      let arr = categories.map((item) => item.name)
      return arr.join('，') || '--'
    },
    saveUpdatePost(id, is_post) {
      if (!id || !id.length) {
        this.$message.info('请选择数据')
        return
      }
      updatePost({ id, is_post }).then((res) => {
        this.$refs.listLayout.getList(this.filterForm)
      })
    },
    // 点击下拉菜单
    handleClickRow({ command: value, row }) {
      switch (value) {
        case 'updatePostUp': {
          // 上架
          this.saveUpdatePost(row.id, 1)
          break
        }
        case 'updatePostDown': {
          // 下架
          this.saveUpdatePost(row.id, 0)
          break
        }
      }
    },
  },
}
</script>

<style scoped></style>
